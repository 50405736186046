<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Overige type SSL-certificaten</h1>
        <h2>Overige SSL certificaten</h2>
        <p>SSL certificaten dragen bij aan de veiligheid van de website, door de verbinding te beveiligen. Dit maakt het mogelijk om vertrouwelijke informatie te verzenden, zoals gegevens van gebruikers die contact opnemen of een bestelling plaatsen.</p>
        <p>De SSL certificaten bieden bezoekers daarnaast nog een ander belangrijk voordeel, ze tonen in de adresbalk van de browser aan dat de website daadwerkelijk het webadres is dat ze wilden bezoeken. Dit voorkomt surfen op frauduleuze kopieën, waardoor de SSL certificaten van grote toegevoegde waarde zijn voor zowel u als website-eigenaar als de bezoekers van uw pagina’s.</p>
        <h3>Beveiliging van de verbinding</h3>
        <p>SSL certificaten zorgen altijd voor een goede beveiliging van de verbinding, om vertrouwelijke gegevens versleuteld te kunnen verzenden. Dat gebeurt echter op diverse niveaus, afhankelijk van het certificaat dat u kiest. We onderscheiden de volgende certificaten:</p>
        <ul>
          <li><router-link to="/ssl-certificaten/standaard-ssl-certificaat/">Standaard SSL</router-link></li>
          <li><router-link to="/ssl-certificaten/multi-domein-ssl-certificaat/">Multi-domein SSL</router-link></li>
          <li><router-link to="/ssl-certificaten/wildcard-ssl-certificaat/">Wildcard SSL </router-link></li>
          <li><router-link to="/ssl-certificaten/ev-certificaat/">EV SSL</router-link></li>
        </ul>
        <p>We adviseren u bij Hosting on Demand graag over het type certificaat dat u het best kunt gebruiken. We doen dat bijvoorbeeld op basis van het type website dat u heeft, net als het type gegevens dat bezoekers versturen en de mate van betrouwbaarheid die u online wilt kunnen aanbieden.</p>
        <h3>Invloed op de vindbaarheid</h3>
        <p>Zowel de verschillende specifieke SSL certificaten als de overige certificaten die er beschikbaar zijn hebben invloed op de vindbaarheid van uw website. Google gebruikt een uitgebreid algoritme met meer dan 200 factoren, waaronder de verbinding op basis van HTTPS. Dit is alleen mogelijk met een geldig SSL-certificaat, waardoor het bijdraagt aan de vindbaarheid van uw website om daar gebruik van te maken.</p>
        <h3>Voordelen van een SSL certificaat bij Hosting on Demand</h3>
        <p>Bij Hosting on Demand profiteert u met een SSL certificaat van:</p>
        <ul>
          <li>Een 100% beveiligde verbinding</li>
          <li>Snelle levering van ieder SSL certificaat</li>
          <li>Betere vindbaarheid in Google</li>
        </ul>
        <p>De overige SSL certificaten zijn net als de andere varianten standaard 1 jaar geldig. Afhankelijk van het type certificaat dat u kiest kunt u deze voor 1 domein, meerdere domeinen of een onbeperkt aantal domeinen gebruiken, afhankelijk van wat u nodig heeft.</p>
        <p>Meer weten over de overige SSL certificaten die er beschikbaar zijn? Neem contact met ons op, dan vertellen we u er graag meer over.</p>
      </div>
      <div class="right-block small-block">
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'OtherTypeSSLCertificates',
  components: {
    Breadcrumbs,
  },
}
</script>